import React from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';

// utils
import ProjectList from './common/ProjectList';
import ProjectCard from './common/ProjectCard';
import ResearchList from './common/ResearchList';
import ResearchCard from './common/ResearchCard';
import ContestList from './common/ContestList';
import ContestCard from './common/ContestCard';

const Work = () => {
  return (
    <div className="fader">
      <Header />
      <div className="container">
        <div className="divider">
          <div className="section-header">Projects</div>
        </div>

        <div className="project-area">
          {ProjectList.map((elem) => (
            <ProjectCard key={elem.id} {...elem} />
          ))}
        </div>

        <div className="divider">
          <div className="section-header">Research</div>
        </div>

        <div className="quote-box">
          <i className="material-icons quote-icon reverse-quote">
            format_quote
          </i>
          <i className="material-icons quote-icon ">format_quote</i>
          <span className="quotes">
            I've been fortunate to be involved in research during undergrad and
            publish five papers in noted IEEE conferences all over India.
          </span>
        </div>

        <div className="project-area">
          {ResearchList.map((elem) => (
            <ResearchCard key={elem.id} {...elem} />
          ))}
        </div>

        <div className="contest-box">
          <div className="divider">
            <div className="section-header">Contests</div>
          </div>

          <div className="quote-box">
            <i className="material-icons quote-icon reverse-quote">
              format_quote
            </i>
            <i className="material-icons quote-icon ">format_quote</i>
            <span className="quotes">
              My projects have been selected and showcased in some of the
              biggest collegiate level technical competitions in India.
            </span>
          </div>

          <div className="contest">
            {ContestList.map((elem) => {
              return <ContestCard key={elem.id} {...elem} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Work;
