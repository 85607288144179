import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [badged, setBadged] = useState('bio-link');

  useEffect(() => {
    try {
      const lastVisit = localStorage.getItem('newUserExp');
      const rightNow = Date.now();
      if (!lastVisit) {
        localStorage.setItem('newUserExp', rightNow);
        setBadged('bio-link badge1');
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <section className="showcase">
      {/* <div className="video-container">
        <video
          src="/assets/videos/mov-3.mp4"
          muted
          autoPlay
          loop
          playsInline
        ></video>
      </div> */}
      <div className="content">
        <img className="content__dp" src="/assets/icons/author.jpg" alt="" />
        <h1 className="name">Rahul Sharma</h1>
        <p className="bio">
          Solution Manager at McKinsey &amp; Company, based in NYC{'  '}
          <span role="img" aria-label="ny">
            🗽
          </span>
          <br />
          Likes design, developer tools, and dunkin' breakfasts{'  '}
          <span role="img" aria-label="bagel">
            🍩
          </span>
          <br />
        </p>
        <div className="bio-links">
          <Link className="bio-link" to="/about">
            About
          </Link>
          <Link className="bio-link work-link" to="/work">
            Work
          </Link>
          <a
            target="_blank"
            data-badge=""
            rel="noopener noreferrer"
            className={badged}
            href="https://rxhl.notion.site/rxhl/Impasse-72dda6b25fe5464e83d3ec260784363f"
          >
            Posts
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
