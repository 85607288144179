import React from 'react';

const SocialIcon = ({ url, img, altText }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <img className="social-icon" src={`/assets/icons/${img}`} alt={altText} />
    </a>
  );
};

export default SocialIcon;
