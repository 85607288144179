import React from 'react';

const ContestCard = ({ img, altText, url }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <img
        className="contest-img"
        src={`/assets/contests/${img}`}
        alt={altText}
      />
    </a>
  );
};

export default ContestCard;
