import React from 'react';
import { Link } from 'react-router-dom';

// utils
import SocialInfo from '../common/SocialInfo';
import SocialIcon from '../common/SocialIcon';

const Footer = () => {
  return (
    <footer>
      <div>
        <Link className="footer-title" to="/">
          <span>Rahul Sharma</span>
        </Link>
      </div>
      <div className="footer-link">
        <Link className="link" to="/about">
          About
        </Link>
        <Link className="link" to="/work">
          Work
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.notion.so/rxhl/Impasse-72dda6b25fe5464e83d3ec260784363f"
          className="link"
        >
          Posts
        </a>
      </div>
      <div className="copyright-box">
        <span>Built from scratch in NY. No templates.</span>
        <span className="rights">
          {new Date().getFullYear()} &copy; Rahul Sharma. All rights reserved.
        </span>
      </div>
      <div className="social-icons">
        {SocialInfo.map((elem) => (
          <SocialIcon key={elem.id} {...elem} />
        ))}
      </div>
    </footer>
  );
};

export default Footer;
