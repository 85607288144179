export const HomeSocial = [
  {
    id: 1,
    name: 'email',
    url: 'mailto:rs2376@cornell.edu',
    img: 'email.png',
    altText: 'email',
  },
  {
    id: 2,
    name: 'Twitter',
    url: 'https://twitter.com/rahulxsharma',
    img: 'twitter.png',
    altText: 'Twitter',
  },
  {
    id: 3,
    name: 'GitHub',
    url: 'https://github.com/rxhl',
    img: 'github.png',
    altText: 'GitHub',
  },
  {
    id: 4,
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/rs2376/',
    img: 'linkedin.png',
    altText: 'LinkedIn',
  },
];

const SocialInfo = [...HomeSocial];

export default SocialInfo;
