const ContestList = [
  {
    id: 1,
    img: 'ti.png',
    altText: 'TI',
    url: 'https://www.youtube.com/watch?v=PKSTyb3SlPk'
  },
  {
    id: 2,
    img: 'ni.png',
    altText: 'NI',
    url: 'http://india.ni.com/niyantra'
  },
  {
    id: 3,
    img: 'freescale.png',
    altText: 'Freescale',
    url: 'https://community.nxp.com/docs/DOC-1284'
  },
  {
    id: 4,
    img: 'quest.png',
    altText: 'Quest',
    url: 'http://www.questingenium.com/'
  }
];

export default ContestList;
