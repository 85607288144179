const ProjectList = [
  {
    id: 10,
    title: 'NYS COVID-19 Tracker',
    img: 'covid.png',
    altText: 'Landmark',
    desc: 'A CLI utility to track COVID-19 cases in New York state based on data from the NYS Dept of Health. Available on npm.',
    demo: 'https://www.npmjs.com/package/covid19nys',
    docs: 'https://github.com/rxhl/covid19nys',
    timeline: 'Spring 2020',
  },
  {
    id: 2,
    title: 'RGQL',
    img: 'blog.png',
    altText: 'RGQL',
    desc: 'A social media site featuring auth and user interactions. Powered by React, GraphQL, Apollo, Heroku and Netlify.',
    demo: 'https://rgql.netlify.com/',
    docs: 'https://github.com/rxhl/rgql',
    timeline: 'Spring 2020',
  },
  {
    id: 4,
    title: 'YipYap',
    img: 'chat.jpg',
    altText: 'YipYap',
    desc: 'A lightweight chat application built with Node, React and Socket.io, hosted on Heroku and Netlify.',
    demo: 'https://yipyap.netlify.com/',
    docs: 'https://github.com/rxhl/yipyap',
    timeline: 'Winter 2019',
  },
  {
    id: 8,
    title: 'Forevernote',
    img: 'notes.png',
    altText: 'Notes',
    desc: 'Minimalist note-taking app featuring Rich-Text editor, built using React, Quill and Firebase.',
    demo: 'https://forevernote.netlify.com/',
    docs: 'https://github.com/rxhl/forevernote',
    timeline: 'Winter 2019',
  },
  {
    id: 3,
    title: 'Elasticsearch for beginners',
    img: 'shard.png',
    altText: 'ES',
    desc: 'A beginner-friendly guide to Elasticsearch 6.x covering its architecture, basic operations and query DSL.',
    demo: 'https://github.com/rxhl/elasticsearch-for-beginners',
    docs: 'https://github.com/rxhl/elasticsearch-for-beginners',
    timeline: 'Summer 2019',
  },

  {
    id: 5,
    title: 'UX',
    img: 'ux.jpg',
    altText: 'UX',
    desc: 'A UX design workflow for creating low to high fidelity prototypes in Sketch.',
    demo: 'https://projects.invisionapp.com/share/E5BT61L6N#/screens/234851564',
    docs: 'https://rxhl.github.io/UX-Design-Workflow/',
    timeline: 'Winter 2018',
  },

  {
    id: 1,
    title: 'Landmark',
    img: 'landmark.jpg',
    altText: 'Landmark',
    desc: 'A yelp clone that displays points of interest in a given location. Built using Flask, Leaflet, Postgres and GeoData API.',
    demo: 'https://landmarkx.herokuapp.com/',
    docs: 'https://github.com/rxhl/Landmark',
    timeline: 'Summer 2017',
  },

  {
    id: 6,
    title: 'Sense',
    img: 'iot7r.png',
    altText: 'Sense',
    desc: 'A wireless sensor network for precipitation monitoring featuring a complete dashboard application for data storage and visualization.',
    demo: 'https://www.youtube.com/watch?v=erZ8TMvV6Q0',
    docs: 'https://www.ece.cornell.edu/ece/programs/graduate-programs/meng-program/design-project-and-annual-poster-session/meng-poster',
    timeline: 'Spring 2017',
  },

  {
    id: 9,
    title: 'Indian Railways',
    img: 'rail.jpg',
    altText: 'Rail',
    desc: 'A data visualization of Indian Railways showing track length, longest route, railway stations and more.',
    demo: 'https://rxhl.github.io/Indian_Railways/',
    docs: 'https://github.com/rxhl/Indian_Railways',
    timeline: 'Spring 2017',
  },
];

export default ProjectList;
