import React from 'react';

// utils
import Header from './layout/Header';
import Footer from './layout/Footer';

const About = () => {
  return (
    <div className="fader">
      <Header />
      <div className="container">
        {/* About Me */}
        <div className="divider about-header about-me">
          <div className="section-header divider-heading">Hello, World!</div>
        </div>

        <div className="project-area about-area">
          <div className="project-card about-card">
            <div className="about-card-head">
              <img width="300px" src="/assets/icons/dev3.png" alt="JS" />
            </div>
            <span className="project-heading about-card__heading">
              Get things done
            </span>
            <div className="project-card-body line-height about-me-body">
              I believe in getting things done, which means wearing multiple
              hats as needed. I work across the stack (React to Rust to
              Redshift) to deliver the best user and developer experience.
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img width="300px" src="/assets/icons/clouds.png" alt="JS" />
            </div>
            <span className="project-heading about-card__heading">
              Cloud-native, Stack-agnostic
            </span>
            <div className="project-card-body line-height about-me-body">
              I have built and deployed at-scale solutions on all three major
              cloud providers and am experienced in both serverless and
              containerized architectures.
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                width="160px"
                src="/assets/icons/learning.png"
                alt="Learning"
              />
            </div>
            <span className="project-heading about-card__heading">
              5-hour rule
            </span>
            <div className="project-card-body line-height about-me-body">
              Learning is the single best investment of our time and I'm a firm
              believer of the{' '}
              <a
                className="links"
                href="https://medium.com/accelerated-intelligence/the-5-hour-rule-if-youre-not-spending-5-hours-per-week-learning-you-re-being-irresponsible-791c3f18f5e6?_lrsc=367a8963-f057-48e9-9b62-e1500f3b5216"
                target="_blank"
                rel="noopener noreferrer"
              >
                5-Hour Rule
              </a>
              . I maintain a list of things I'm currently learning, ranging from
              data lakes to Spanish.
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img width="180px" src="/assets/icons/ch.png" alt="Poetry" />
            </div>
            <span className="project-heading about-card__heading">
              The road not taken
            </span>
            <div className="project-card-body line-height about-me-body">
              I enjoy reading poetry, particularly the ones reflecting on life
              and nature. My favorites include{' '}
              <a
                className="links"
                href="https://zenpencils.com/comic/60-robert-frost-the-road-not-taken/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Road Not Taken
              </a>
              ,{' '}
              <a
                className="links"
                href="https://www.poetryfoundation.org/poems/46565/ozymandias"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ozymandias
              </a>
              , and{' '}
              <a
                className="links"
                href="https://www.poetryfoundation.org/poems/43187/the-highwayman"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Highwayman
              </a>
              .
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img width="300px" src="/assets/icons/fall.png" alt="JS" />
            </div>
            <span className="project-heading about-card__heading">Fall</span>
            <div className="project-card-body line-height about-me-body">
              I look forward to the fall season every year to enjoy the
              red-yellow trees in all their glory.
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img width="300px" src="/assets/icons/nyc.jpg" alt="JS" />
            </div>
            <span className="project-heading about-card__heading">
              The Big Apple
            </span>
            <div className="project-card-body line-height about-me-body">
              I live and work in NYC, famous for its iconic skyline and{' '}
              <a
                className="links"
                href="https://en.wikipedia.org/wiki/New_York-style_pizza"
                target="_blank"
                rel="noopener noreferrer"
              >
                foldabale pizzas
              </a>
              . My ongoing goal is to visit every speakeasy in the city.
            </div>
          </div>
        </div>

        <div className="divider about-header">
          <div className="section-header divider-heading">
            Places I have worked at
          </div>
        </div>

        {/* Companies */}
        <div className="project-area school-area">
          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                src="/assets/icons/mck2.png"
                alt="McKinsey"
                width="120px"
                loading="lazy"
              />
            </div>
            <div className="project-card-body line-height">
              <div className="align-center">
                <span className="project-heading">McKinsey &amp; Company</span>
                , New York, NY <br />
                Solution Manager, April 2020 – Present
              </div>
              <span>
                McKinsey &amp; Company is a global management consulting firm
                helping organizations across the private, public, and social
                sectors to create{' '}
                <a
                  className="links"
                  href="https://www.mckinsey.com/about-us/overview/change-that-matters"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  change that matters
                </a>
                .
              </span>
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                src="/assets/icons/mpg2.jpeg"
                alt="MPG"
                width="120px"
                loading="lazy"
              />
            </div>
            <div className="project-card-body line-height">
              <div className="align-center">
                <span className="project-heading">Millennium</span>
                , New York, NY <br />
                Software Engineer, May 2018 – April 2020
              </div>
              <span>
                Millennium is one of world's largest multi-strategy hedge funds
                with an AUM of over $37 billion. I worked as a Data Analyst /
                Software Engineer, building products for the Equities Management
                group.
              </span>
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                src="/assets/icons/sby.png"
                alt="Seabury"
                width="230px"
                loading="lazy"
              />
            </div>
            <div className="project-card-body line-height">
              <div className="align-center">
                <span className="project-heading">Seabury Capital</span>, New
                York, NY <br />
                Software Engineer, Jul 2017 – May 2018
              </div>
              <span>
                Seabury Capital is a boutique investment management firm focused
                in aviation. I worked as a Programmer Analyst, building flight
                profitability applications for major airline carriers worldwide.
              </span>
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <img width="200px" src="/assets/icons/bosch.jpg" alt="Bosch" />
            </div>
            <div className="project-card-body line-height">
              <div className="align-center">
                <span className="project-heading align-center">
                  Robert Bosch
                </span>
                , Bangalore, India <br />
                Research Intern, Jan 2012 – Apr 2016
              </div>
              <span>
                Worked with Bosch's R&amp;D wing in India, developed prototypes
                and authored reports on home automation. Received high accolades
                from the Bosch HQ in Germany.
              </span>
            </div>
          </div>
        </div>

        {/* School */}
        <div className="divider school about-header">
          <div className="section-header divider-heading ">
            Places I went school to
          </div>
        </div>

        <div className="project-area school-area">
          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                width="160px"
                src="/assets/icons/cornell.png"
                alt="Cornell"
              />
            </div>
            <div className="about-card-body line-height">
              <div className="project-header">
                <div className="about-card-title">
                  <span className="project-heading about-heading">
                    Cornell University
                  </span>
                  , Ithaca, NY
                  <br />
                  M.Eng., Aug 2016 &#8211; May 2017
                </div>
                <br />
              </div>
              I went here for my Masters in Electrical and Computer Engineering.
              I was advised by{' '}
              <a
                className="links"
                href="http://skovira.ece.cornell.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dr. Joe Skovira
              </a>{' '}
              where I built{' '}
              <a
                className="links"
                href="https://www.youtube.com/watch?v=erZ8TMvV6Q0"
                target="_blank"
                rel="noopener noreferrer"
              >
                this
              </a>{' '}
              (awarded as one of the{' '}
              <a
                className="links"
                href="https://www.ece.cornell.edu/ece/programs/graduate-programs/meng-program/design-project-and-annual-poster-session/meng-poster"
                target="_blank"
                rel="noopener noreferrer"
              >
                best projects
              </a>{' '}
              in my batch). I was also a Teaching Assistant for{' '}
              <a
                className="links"
                href="https://classes.cornell.edu/browse/roster/FA18/class/ECE/2100"
                target="_blank"
                rel="noopener noreferrer"
              >
                ECE 2100
              </a>{' '}
              and a member of{' '}
              <a
                className="links"
                href="http://hkn.ece.cornell.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Eta Kappa Nu
              </a>{' '}
              (ECE Honor Society).
            </div>
          </div>

          <div className="project-card about-card">
            <div className="about-card-head">
              <a
                title="Bosch"
                className="links buttons"
                href="https://www.bmsce.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img width="170px" src="/assets/icons/bmsce.png" alt="BMSCE" />
              </a>
            </div>
            <div className="about-card-body line-height">
              <div className="project-header">
                <div className="about-card-title">
                  <span className="project-heading about-heading">
                    B.M.S. College of Engineering
                  </span>
                  , Bangalore, India
                  <br />
                  B.Eng., Sep 2012 &#8211; May 2016
                </div>
                <br />
              </div>
              I went here for my Bachelors in Electronics and Instrumentation
              Engineering. I graduated summa cum laude and was awarded the best
              outgoing student. I was involved in myriad activities on campus
              &#8211; Founder of the{' '}
              <a
                className="links"
                href="https://www.facebook.com/Mathbmcse/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Math Society
              </a>
              , Chairman of the{' '}
              <a
                className="links"
                href="https://www.ieee.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                IEEE
              </a>{' '}
              and{' '}
              <a
                className="links"
                href="https://www.isa.org/default.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                ISA
              </a>{' '}
              chapters along with being the Student Placement Coordinator.
            </div>
          </div>
        </div>
        <div className="divider about-header award">
          <div className="section-header divider-heading">Honors</div>
        </div>

        <div className="project-area about-area">
          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                className="award-img"
                src="/assets/icons/tata.png"
                alt="TATA"
              />
            </div>
            <div className="project-card-body">
              <span>
                <a
                  className="links"
                  href="http://www.jntataendowment.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  J.N. Tata Scholarship
                </a>{' '}
                for higher education of Indians, 2016. (Acceptance rate &asymp;
                1%)
              </span>
            </div>
          </div>
          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                className="award-img"
                src="/assets/icons/isa.png"
                alt="ISA"
              />
            </div>
            <div className="project-card-body">
              <span>
                <a
                  className="links"
                  href="/assets/files/isa-award.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISA Excellence Award
                </a>{' '}
                for exceptional contribution towards the automation community,
                2015.
              </span>
            </div>
          </div>
          <div className="project-card about-card">
            <div className="about-card-head">
              <img
                className="award-img"
                src="/assets/icons/ieee.png"
                alt="IEEE"
              />
            </div>
            <div className="project-card-body">
              <span>
                IEEE Outstanding Student Volunteer Award, Bangalore Section,
                India, 2015.
              </span>
            </div>
          </div>
        </div>

        {/* About Me */}
        <div className="divider about-header about-me">
          <div className="section-header divider-heading">Certifications</div>
        </div>
        <div className="project-area about-area">
          <div className="project-card about-card">
            <div className="about-card-head cert-head">
              <img
                className="cert-img"
                src="/assets/certs/saa.png"
                alt="AWS_SAA"
              />
            </div>
          </div>
          <div className="project-card about-card">
            <div className="about-card-head cert-head">
              <img
                className="cert-img"
                src="/assets/certs/mls.png"
                alt="AWS_MLS"
              />
            </div>
          </div>
          <div className="project-card about-card">
            <div className="about-card-head cert-head">
              <img
                className="cert-img"
                src="/assets/certs/lakehouse.png"
                alt="Databricks_Lakehouse"
              />
            </div>
          </div>
          <div className="project-card about-card">
            <div className="about-card-head cert-head">
              <img
                className="cert-img"
                src="/assets/certs/finops.png"
                alt="FinOps"
              />
            </div>
          </div>
          <div className="project-card about-card">
            <div className="about-card-head cert-head">
              <img
                className="cert-img"
                src="/assets/certs/notion.png"
                alt="Notion Essentials"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
