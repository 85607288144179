import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  place-content: center center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
`;

const Muttley = styled.img`
  width: 100px;
  height: auto;
`;

const Error = styled.span`
  font-size: 2rem;
  justify-self: center;
`;

const NotFound = () => {
  return (
    <Wrapper>
      <Link style={{ justifySelf: 'center' }} exact to="/">
        <Muttley src="/assets/icons/muttley.jpg" alt="Not Found" />
      </Link>
      <Error>404</Error>
    </Wrapper>
  );
};
export default NotFound;
