const ResearchList = [
  {
    id: 1,
    title: 'Water Quality Management System',
    img: 'lake.jpg',
    altText: 'WQMS',
    desc: 'Surveyed ~10 lakes in Bangalore (India), analysing their chemical composition and predicting their state over time.',
    paper: 'https://ieeexplore.ieee.org/abstract/document/7906810',
  },
  {
    id: 3,
    title: 'Smart Headphones with ANC',
    img: 'anc.png',
    altText: 'ANC',
    desc: 'A novel concept of low-cost headphones with Active Noise Cancellation (Kalman filter) and Speech Recognition.',
    paper: 'https://ieeexplore.ieee.org/document/7225443',
  },
  {
    id: 4,
    title: 'Travel Aid for the Blind',
    img: 'echo.jpg',
    altText: 'Foresight',
    desc: 'Wearable device for the visually challenged, helping them detect physical obstacles using echolocation.',
    paper: 'https://ieeexplore.ieee.org/document/7411266',
  },
];

export default ResearchList;
