import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <Link to="/" className="logo">
        <span>Rahul Sharma</span>
      </Link>
      <NavLink activeClassName="active" to="/about" className="link">
        About
      </NavLink>
      <NavLink activeClassName="active" to="/work" className="link">
        Work
      </NavLink>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.notion.so/rxhl/Impasse-72dda6b25fe5464e83d3ec260784363f"
        className="link"
      >
        Posts
      </a>
    </header>
  );
};

export default Header;
