import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

// utils
import './main.css';
import NotFound from './components/NotFound';
import About from './components/About';
import Work from './components/Work';
import Home from './components/Home';

// Universal Analytics (GA3)
// ReactGA.initialize('UA-89046448-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

// GTM and GA4 setup
const tagManagerArgs = {
  gtmId: 'GTM-P5WG6KDW',
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/about" component={About} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
