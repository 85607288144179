import React from 'react';

const ResearchCard = ({ title, img, altText, desc, paper }) => {
  return (
    <div className="project-card">
      <div className="pub-card-head">
        <img
          className="project-img"
          src={`/assets/projects/${img}`}
          alt={altText}
          loading="lazy"
        />
      </div>
      <div className="project-card-body">
        <div className="project-header pub-body">
          <span className="pub-heading project-heading">{title}</span>
        </div>
        {desc}
        <div className="button-container">
          <span>
            <a
              title="Paper"
              className="links buttons"
              href={paper}
              target="_blank"
              rel="noopener noreferrer"
            >
              Paper
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResearchCard;
